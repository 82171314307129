import React from 'react';
import styled from 'styled-components';
import { ThxProps } from './Theme';

// prettier-ignore
export const icons = {
  linkedin: 'M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z',
  twitter: 'M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z',
  github: 'M438.857 73.143c242.286 0 438.857 196.571 438.857 438.857 0 193.714-125.714 358.286-300 416.571-22.286 4-30.286-9.714-30.286-21.143 0-14.286 0.571-61.714 0.571-120.571 0-41.143-13.714-67.429-29.714-81.143 97.714-10.857 200.571-48 200.571-216.571 0-48-17.143-86.857-45.143-117.714 4.571-11.429 19.429-56-4.571-116.571-36.571-11.429-120.571 45.143-120.571 45.143-34.857-9.714-72.571-14.857-109.714-14.857s-74.857 5.143-109.714 14.857c0 0-84-56.571-120.571-45.143-24 60.571-9.143 105.143-4.571 116.571-28 30.857-45.143 69.714-45.143 117.714 0 168 102.286 205.714 200 216.571-12.571 11.429-24 30.857-28 58.857-25.143 11.429-89.143 30.857-127.429-36.571-24-41.714-67.429-45.143-67.429-45.143-42.857-0.571-2.857 26.857-2.857 26.857 28.571 13.143 48.571 64 48.571 64 25.714 78.286 148 52 148 52 0 36.571 0.571 70.857 0.571 81.714 0 11.429-8 25.143-30.286 21.143-174.286-58.286-300-222.857-300-416.571 0-242.286 196.571-438.857 438.857-438.857zM166.286 703.429c1.143-2.286-0.571-5.143-4-6.857-3.429-1.143-6.286-0.571-7.429 1.143-1.143 2.286 0.571 5.143 4 6.857 2.857 1.714 6.286 1.143 7.429-1.143zM184 722.857c2.286-1.714 1.714-5.714-1.143-9.143-2.857-2.857-6.857-4-9.143-1.714-2.286 1.714-1.714 5.714 1.143 9.143 2.857 2.857 6.857 4 9.143 1.714zM201.143 748.571c2.857-2.286 2.857-6.857 0-10.857-2.286-4-6.857-5.714-9.714-3.429-2.857 1.714-2.857 6.286 0 10.286s7.429 5.714 9.714 4zM225.143 772.571c2.286-2.286 1.143-7.429-2.286-10.857-4-4-9.143-4.571-11.429-1.714-2.857 2.286-1.714 7.429 2.286 10.857 4 4 9.143 4.571 11.429 1.714zM257.714 786.857c1.143-3.429-2.286-7.429-7.429-9.143-4.571-1.143-9.714 0.571-10.857 4s2.286 7.429 7.429 8.571c4.571 1.714 9.714 0 10.857-3.429zM293.714 789.714c0-4-4.571-6.857-9.714-6.286-5.143 0-9.143 2.857-9.143 6.286 0 4 4 6.857 9.714 6.286 5.143 0 9.143-2.857 9.143-6.286zM326.857 784c-0.571-3.429-5.143-5.714-10.286-5.143-5.143 1.143-8.571 4.571-8 8.571 0.571 3.429 5.143 5.714 10.286 4.571s8.571-4.571 8-8z',
  stackoverflow: 'M736.571 932.571h-638.857v-274.286h-91.429v365.714h821.714v-365.714h-91.429v274.286zM198.286 633.143l18.857-89.714 447.429 94.286-18.857 89.143zM257.143 419.429l38.286-83.429 414.286 193.714-38.286 82.857zM372 216l58.286-70.286 350.857 293.143-58.286 70.286zM598.857 0l272.571 366.286-73.143 54.857-272.571-366.286zM188.571 840.571v-90.857h457.143v90.857h-457.143z',
  sun: 'M841.143 512c0-181.714-147.429-329.143-329.143-329.143s-329.143 147.429-329.143 329.143 147.429 329.143 329.143 329.143 329.143-147.429 329.143-329.143zM998.857 670.286c-1.714 5.714-6.286 9.714-11.429 11.429l-166.857 54.857v174.857c0 5.714-2.857 11.429-7.429 14.857-5.143 3.429-10.857 4.571-16.571 2.286l-166.857-53.714-102.857 141.714c-3.429 4.571-9.143 7.429-14.857 7.429s-11.429-2.857-14.857-7.429l-102.857-141.714-166.857 53.714c-5.714 2.286-11.429 1.143-16.571-2.286-4.571-3.429-7.429-9.143-7.429-14.857v-174.857l-166.857-54.857c-5.143-1.714-9.714-5.714-11.429-11.429s-1.143-12 2.286-16.571l102.857-141.714-102.857-141.714c-3.429-5.143-4-10.857-2.286-16.571s6.286-9.714 11.429-11.429l166.857-54.857v-174.857c0-5.714 2.857-11.429 7.429-14.857 5.143-3.429 10.857-4.571 16.571-2.286l166.857 53.714 102.857-141.714c6.857-9.143 22.857-9.143 29.714 0l102.857 141.714 166.857-53.714c5.714-2.286 11.429-1.143 16.571 2.286 4.571 3.429 7.429 9.143 7.429 14.857v174.857l166.857 54.857c5.143 1.714 9.714 5.714 11.429 11.429s1.143 11.429-2.286 16.571l-102.857 141.714 102.857 141.714c3.429 4.571 4 10.857 2.286 16.571z',
  moon: 'M721.143 744.571c-20.571 3.429-41.714 5.143-62.857 5.143-212 0-384-172-384-384 0-72.571 21.143-143.429 59.429-204-152 45.143-260.571 184.571-260.571 350.286 0 201.714 164 365.714 365.714 365.714 110.286 0 213.714-50.286 282.286-133.143zM837.143 696c-71.429 154.857-228 254.857-398.286 254.857-241.714 0-438.857-197.143-438.857-438.857 0-237.143 185.714-429.714 422.286-438.286 16-0.571 29.143 8.571 34.857 22.286 6.286 14.286 2.286 30.857-8.571 41.143-65.143 59.429-101.143 140.571-101.143 228.571 0 171.429 139.429 310.857 310.857 310.857 45.143 0 88.571-9.714 130.286-29.143 14.286-6.286 30.286-3.429 41.143 7.429s13.714 27.429 7.429 41.143z',
};

const widths = {
  linkedin: 877.7142857142857,
  twitter: 950.8571428571428,
  github: 877.7142857142857,
  stackoverflow: 877.7142857142857,
  sun: 1024,
  moon: 843.4102857142857,
};

const SVG = styled('svg')`
  transition: fill 0.2s;
  fill: ${({ theme }: ThxProps) => theme.main.icon};
  &:hover {
    fill: ${({ theme }: ThxProps) => theme.main.activeIcon};
  }
`;

export interface IconProps {
  icon: keyof typeof icons;
}

export const Icon = ({ icon }: IconProps) => {
  const viewWidth = widths[icon];
  const iconWidth = (viewWidth / 1024) * 22;
  return (
    <SVG width={iconWidth} height="22" viewBox={`0 0 ${viewWidth} 1024`}>
      <path d={icons[icon]} />
    </SVG>
  );
};
